import {Button, Grid, Paper, Typography} from "@material-ui/core";
import React, {useState} from "react";
import {useStyles} from "./style";
import {createMuiTheme, ThemeProvider, useTheme} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {PhaseEnum, PhaseService, ServiceEnum} from "../../../models/catalogue.model";
import {getPageFromService} from "../../../utils/user.utils";
import {useNavigate} from "react-router";
import {sortPhaseService} from "../../../utils/suiviConso.util";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import ListAltIcon from '@material-ui/icons/ListAlt';
import HearingIcon from '@material-ui/icons/Hearing';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import WcIcon from '@material-ui/icons/Wc';
import SpaIcon from '@material-ui/icons/Spa';
import {Favorite} from "@material-ui/icons";
import FlareIcon from '@material-ui/icons/Flare';
import clsx from "clsx";
import {SuiviConsommationModel} from "../../../models/suivi-consommation.model";
import {log} from "../../../utils/log";
import LearnerForbiddenModuleResumePopup from "../LearnerForbiddenModuleResume";
import {ModuleDetailsService} from "../../../services/module-details.service";
import {SessionDetailsService} from "../../../services/session-details.service";
import {SessionDetail} from "../../../models/session-detail";
import {ModuleDetails} from "../../../models/module-details";
import Alert from "@material-ui/lab/Alert";

interface LearnerPhaseServiceListProps {
    phase: PhaseEnum;
    userPhasesAndServices: SuiviConsommationModel[];
    displayBackButton: boolean;
    filteredServicesForThisPhase: PhaseService[];
    onClickSuiviConso: (suiviConso: SuiviConsommationModel) => void;
}


export function getServiceLogo(service: ServiceEnum) {
    switch (service) {
        case ServiceEnum.CV:
            return <ListAltIcon/>
        case ServiceEnum.PITCH:
            return <RecordVoiceOverIcon/>;
        case ServiceEnum.DIVERSITY:
            return <WcIcon/>;
        case ServiceEnum.TRAINING_AND_DEBRIEF:
            return <PeopleOutlineIcon/>;
        case ServiceEnum.BILAN_PRO: {
            return <AccountCircleIcon/>;
        }
        case ServiceEnum.WELL_BEING: {
            return <SpaIcon/>;
        }
        case ServiceEnum.NETWORKING: {
            return <GroupAddIcon/>;
        }
        case ServiceEnum.DIGITAL_IMAGE: {
            return <LinkedInIcon/>;
        }
        case ServiceEnum.RESILIENCE: {
            return <Favorite/>;
        }
        case ServiceEnum.SELF_CONFIANCE: {
            return <InsertEmoticonIcon/>;
        }
        case ServiceEnum.DEVELOP_COMMUNICATION: {
            return <HearingIcon/>;
        }
        case ServiceEnum.UNDERSTAND_MY_PERSONALITY: {
            return <AutorenewIcon/>;
        }
        case ServiceEnum.RAYONNER: {
            return <FlareIcon/>;
        }
        case ServiceEnum.VISION_360: {
            return <AutorenewIcon/>;
        }
        default: {
            return '';
        }
    }
}


export default function LearnerPhaseServiceList(props: LearnerPhaseServiceListProps) {
    const theme = useTheme();
    const classes = useStyles(theme);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [clickedModuleDetails, setClickedModuleDetails] = useState<ModuleDetails | null>(null);
    const [clickedModuleSessions, setClickedModuleSessions] = useState<SessionDetail[]>([]);
    const moduleDetailsService = new ModuleDetailsService();
    const sessionDetailsService = new SessionDetailsService();
    const [errorMessage, setErrorMessage] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [clickedModule, setClickedModule] = useState<PhaseService | null>(null);


    const buttonTheme = createMuiTheme({
        typography: {
            button: {
                textTransform: 'none'
            },
        },
    });
    const enableCatalogueForUser = (cat: PhaseService): boolean => {
        // Does user has access to this Phase and service?
        return props.userPhasesAndServices?.map(p => p.phase).includes(cat.phase) && props.userPhasesAndServices?.map(p => p.service)?.includes(cat.service);
    }

    const handleClose = () => {
        setOpenDialog(false);
    }

    const showModuleDetails = async (phaseService: PhaseService): Promise<void> => {
        setOpenDialog(true);
        try {
            const moduleDetails = await moduleDetailsService.getOneByPhaseAndService(phaseService.phase, phaseService.service);
            const sessionsDetails = await sessionDetailsService.getByModule(moduleDetails.moduleDetailsID);
            setClickedModuleDetails(moduleDetails);
            setClickedModuleSessions(sessionsDetails);
            setClickedModule(phaseService);
        } catch (e) {
            log.error(e);
            setClickedModuleDetails(null);
            setClickedModuleSessions([]);
            setClickedModule(null);
            setErrorMessage(t('unexpectedErrorLoading'));
        }

    }

    const routeToService = (phase: PhaseEnum, service: ServiceEnum) => {
        log.debug("will route")
        const suiviConso = props.userPhasesAndServices.find(suiviConso =>
            suiviConso.phase === phase && suiviConso.service === service);
        if (suiviConso) {
            props.onClickSuiviConso(suiviConso);
        }
        const url = getPageFromService(phase, service);
        navigate(url);
    }

    const getServiceName = (service: ServiceEnum): string => {
        const nameAndDesc = t(`catalogue.services.learner.${service}`);
        return nameAndDesc.split(':')?.[0]?.trim();
    }

    const getServiceDescription = (service: ServiceEnum): string => {
        const nameAndDesc = t(`catalogue.services.learner.${service}`);
        const colonIndex = nameAndDesc.indexOf(":");
        return nameAndDesc.substr(colonIndex + 1).trim();
    }

    const allServicesSorted = (): PhaseService[] => {
        return sortPhaseService(props.filteredServicesForThisPhase)
            .filter(phaseService => !phaseService.hideInModuleList);
    }

    const userNotClickedOnModules = (clickedModule: PhaseService) => {
        return props.userPhasesAndServices.find(catalogue => catalogue.service === clickedModule.service &&
            catalogue.phase === clickedModule.phase &&
            catalogue.firstClick === false); // Don't put !catalogue.firstClick since it will consider undefined as false
    }

    const existAtLeastOneServiceToShow = allServicesSorted().length > 0;


    return (<>
            {errorMessage && <Alert severity="error" className={classes.row}>{errorMessage}</Alert>}
            {clickedModule && <LearnerForbiddenModuleResumePopup description={clickedModuleDetails?.description || ''}
                                                                 sessionsDetails={clickedModuleSessions}
                                                                 goal={clickedModuleDetails?.goal || ''}
                                                                 nbSession={clickedModuleDetails?.nbSession || 0}
                                                                 phase={clickedModule.phase}
                                                                 service={clickedModule.service}
                                                                 open={openDialog}
                                                                 handleClose={handleClose}/>}
            {existAtLeastOneServiceToShow && <Grid container className={classes.row} direction="row"
                                                   justify={'space-between'} alignItems={'flex-end'}>
                <Typography variant="h6" className={classes.phases}>
                    {t(`catalogue.phases.${props.phase}`)}
                </Typography>
            </Grid>}
            <Grid container spacing={3} className={classes.row} direction="row"
                  justify={'flex-start'} alignItems={'stretch'}>
                {
                    allServicesSorted().map((cat: PhaseService, index: number) => (
                        <Grid item className={classes.column}
                              xs={12} md={6} lg={4}
                              key={cat.phase + cat.service}>
                            <ThemeProvider theme={buttonTheme}>
                                <Button component={Paper}
                                        className={
                                            clsx(classes.paper)
                                        }
                                        onClick={(event: any) => {
                                            if (enableCatalogueForUser(cat)) {
                                                routeToService(cat.phase, cat.service);
                                            } else {
                                                showModuleDetails(cat);
                                            }
                                        }}
                                >
                                    <div className={classes.consoTitle}>
                                        <div className={classes.titleAndLogo}>
                                            {getServiceLogo(cat.service)}
                                            <span>&nbsp;</span>
                                            <Typography
                                                variant="subtitle2"
                                                className={classes.subtitle2}>{getServiceName(cat.service)}</Typography>
                                        </div>
                                        <Typography
                                            variant="caption"
                                            className={classes.caption}>{getServiceDescription(cat.service)}</Typography>
                                    </div>
                                    <div className={classes.content}>
                                    </div>
                                </Button>
                            </ThemeProvider>
                        </Grid>))
                }
            </Grid>
        </>
    );
}
